//引入react jsx写法的必须
//引入需要用到的页面组件
// import Home from './pages/home'
// import Index from './pages/index';
import Vlog from './pages/vlog';
import Index from './pages/index'
import City from './pages/city';
import Country1 from './pages/country1';
import Country2 from './pages/country2';
import Country3 from './pages/country3';
import Country4 from './pages/country4';
import Details from './pages/details';
import PageDetails from './pages/pagedetails';
import Introduce from './pages/introduce';
import Download from './pages/download';
//引入一些模块introduce

let router = [

    {
        path: "/",
        title: "首页",
        component: Index,
        exact: true
    },
    {
        path: "/index",
        title: "首页",
        component: Index,
        exact: true
    },
    {
        path: "/vlog",
        title: "Vlog",
        component: Vlog,
        exact: true
    },
    {
        path: "/city",
        title: "City",
        component: City,
        exact: true
    },
    {
        title: "Country1",
        path: "/country1",
        component:Country1,
        exact: true
    },
    {
        title: "Country2",
        path: "/country2",
        component:Country2,
        exact: true
    },
    {
        title: "Country3",
        path: "/country3",
        component:Country3,
        exact: true
    },
    {
        title: "Country4",
        path: "/country4",
        component:Country4,
        exact: true
    },
    {
        path: "/details",
        title: "Details",
        component: Details,
        exact: true
    },
    {
        path: "/pagedetails",
        title: "PageDetails",
        component: PageDetails,
        exact: true
    },

    {
        path: "/introduce",
        title: "Introduce",
        component: Introduce,
        exact: true
    },
    {
        path: "/download",
        title: "Download",
        component: Download,
        exact: true
    },
];

export default router;