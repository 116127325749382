import React, { Component } from 'react';
import Header from'../component/header'
import Footer from'../component/footer'
import {Link } from "react-router-dom";
import '../style/details.css'
import {
    SitePageView
  } from "../api/api";
export default class pageView extends Component{
    state={
        data:{

        }
    }
    componentWillMount(){
        const params = new URLSearchParams(this.props.location.search)
        if (params.get("id")) {
            this.SiteArticleView(params.get("id"))
        }
    }
    async SiteArticleView(id){
        let res = await SitePageView({id:id})
        if(res.code == 0){
          this.setState({
            data: res.data
          })
        }
    }
    goBack(){
        this.props.history.goBack()
    }
    render() {
        let content =''
        //图文
        content = <div className="details">
            <div className="title">
                {this.state.data.title}
            </div>
            <div className="content" dangerouslySetInnerHTML={{__html: this.state.data.content}} />
        </div>

        return (
        <div>
            <Header city={true} />
            <div className="breadcrumb w">
                <Link to='./' ><span>路图全球旅行摄影</span></Link>
                <img src={require('../assets/img/next_page.png')} alt="" />
                <span  onClick={()=>{this.goBack()}} >文章</span>
                <img src={require('../assets/img/next_page.png')} alt="" />
                <span>详情信息</span>
            </div>
            <div className="news_details w">
            {content}
            </div>
            <Footer />
        </div>
        )
    }
}