import React, { Component } from 'react';
import { Player } from 'video-react';
import Header from '../component/header'
import Footer from '../component/footer'
import {Link} from "react-router-dom";
import { Carousel } from 'antd';
import '../style/index.css'
import {
    SiteHome
  } from "../api/api";
  const rdom = require('react-dom');  
export default class Index extends Component {
    state = {
        data: {
            bannerOne:[],
            bannerTow:[],
            countries:[ ],
            desination:[],
            vlog:{list:[{atc_attach:[{file:''}]}]},
            live:{
                img:''
            }
        },
    }
    handlePrev = (ref)=>{
        this.refs[ref].prev(); //ref = img
      }
      handleNext = (ref)=>{
        this.refs[ref].next();
      }
    componentDidMount() {
        this.SiteHome()
        
    }
    CityScroll(){
        let anchorElement = document.getElementById('city')
        if(anchorElement) {
            setTimeout(() => {
            anchorElement.scrollIntoView({behavior: "smooth", block: "start", inline: "start"}) 
            }, 30)
        }
    }
    async SiteHome(){
        let res = await SiteHome()
        if(res.code == 0){
          this.setState({
            data: res.data
          })
        }
    }
    
    navScroll() {
        let anchorElement = document.getElementById('header_video')
            if(anchorElement) { 
                setTimeout(() => {
                    anchorElement.scrollIntoView({behavior: "smooth", block: "start", inline: "start"}) 
                }, 1000)
            }
    }
    handleScroll(e) { 
        const ele = rdom.findDOMNode(this);
        if (e.nativeEvent.deltaY <= 0) {
          /* scrolling up */
          if(ele.scrollTop <= 0) {
            e.preventDefault();
            let anchorElement = document.getElementById('header_video')
            if(anchorElement) { 
                anchorElement.scrollIntoView({behavior: "smooth", block: "start", inline: "start"}) 
                setTimeout(() => {
                anchorElement.scrollIntoView({behavior: "smooth", block: "start", inline: "start"}) 
                }, 30)
            }
            }
        } 
        else
        {
          /* scrolling down */
          if(ele.scrollTop + ele.clientHeight >= ele.scrollHeight) {
            e.preventDefault();
            let anchorElement = document.getElementById('header')
            if(anchorElement) { 
                setTimeout(() => {
                anchorElement.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
            }, 30)
            }
          }
        }
      }
    render() {
        let vlogLength=''
        if (this.state.data.vlog.file) {
            vlogLength = <div className="content w">
                    <div className="title">
                        <img src={require('../assets/img/vlog.png')} alt="" />
                    </div>
                    <video controls className="video" width="100%" height="auto" src={this.state.data.vlog.file}  poster={this.state.data.vlog.cover} ></video>
                </div>
        } 
        if(this.props.location.state){
            if(this.props.location.state.city){
                this.CityScroll()
            }
        }
        
        return (
            <div>
                <div className="header_video"  onWheel={(e) => this.handleScroll(e)} id="header_video">
                    <video autoPlay className="video" muted loop width="100%" height="auto" src={this.state.data.live.img}></video>
                    <div className="box video_box">
                        <img onClick={()=>{this.navScroll()}} src={require('../assets/img/leave.png')} alt="" className="leave" />
                    </div>
                </div>
                <Header />
                <div id="__banner">
                    <Carousel ref="bannerOne">
                    {this.state.data.bannerOne.map((val, index) => (
                            <div key={index}>
                                <img alt="" src={val.img} style={{width: '100%'}}/>
                            </div>
                        ))}
                    </Carousel>
                    <img className="iconl" src={require('../assets/img/left.png')} onClick={()=>{this.handlePrev('bannerOne')}} alt="" />
                    <img className="iconr" src={require('../assets/img/right.png')} onClick={()=>{this.handleNext('bannerOne')}} alt="" />
                </div>

                <div className="content ">
                    <div className="title w">
                        <img src={require('../assets/img/kepian.png')} alt="" />
                       
                    </div>
                    <div className="nonnative_tabs w">
                        <div className="item">
                        <Link to={{pathname:"/country1"}} >
                            <img src={require('../assets/img/tab1.png')} alt="" />
                            <span>情侣</span></Link>
                        </div>
                        <div className="item">
                        <Link to={{pathname:"/country2"}} >
                            <img src={require('../assets/img/tab2.png')} alt="" />
                            <span>个人</span></Link>
                        </div>
                        <div className="item">
                        <Link to={{pathname:"/country3"}} >
                            <img src={require('../assets/img/tab3.png')} alt="" />
                            <span>亲子</span></Link>
                        </div>
                        <div className="item">
                        <Link to={{pathname:"/country4"}} >
                            <img src={require('../assets/img/tab4.png')} alt="" />
                            <span>闺蜜</span></Link>
                        </div>
                    </div>
                    <div id="__banner2">
                        <Carousel ref="bannerTow">
                        {this.state.data.bannerTow.map((val, index) => (
                                <div key={index}>
                                    <img alt="" src={val.img} style={{width: '100%' }}/>
                                </div>
                            ))}
                        </Carousel>
                        <img className="iconl" src={require('../assets/img/left.png')} onClick={()=>{this.handlePrev('bannerTow')}} alt="" />
                        <img className="iconr" src={require('../assets/img/right.png')} onClick={()=>{this.handleNext('bannerTow')}} alt="" />
                    </div>
                </div>
                {vlogLength}
                <div className="content w">
                    <div className="title " id="city">
                    <img src={require('../assets/img/mudidi.png')} alt="" />
                    </div>

                    <ul className="bourn_list">
                        {
                            this.state.data.desination.map((item,index)=>{
                                return <li key={index} >
                                <Link to={{pathname:"/city", search:`?desin_id=${item.id}&name=${item.name}`}}> 
                                        <img alt="" src={item.cover||require('../assets/1.png')}/>
                                   
                                    <div className="name">
                                        <p className="p1">{item.name}</p>
                                        <p className="p2">{item.shorttitle}</p>
                                    </div>
                                    <div className="child">
                                    {
                                        item.children.map( childItem =>{
                                            return <span key={childItem.name}>{childItem.name}</span>
                                        })
                                    }
                                    </div>

                                </Link></li>
                            })
                        }
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
                <Footer />
            </div>
        )
    }
}