import React, { Component } from 'react';
import Header from '../component/header'
import Footer from'../component/footer'
import '../style/download.css'
// import moment from "moment";
import QRCode  from 'qrcode.react';
import {
    DownloadUrl
  } from "../api/api";
export default class Download extends Component {
    state={
        data:{
            auto:''
        }
    }
    componentDidMount(){
        this.DownloadUrl()
    }
    async DownloadUrl(){
        let res = await DownloadUrl({app_id:8})
        if(res.code === 0){
          this.setState({
            data: res.data
          })

        }
    }
    download(type){
        console.log(this.state.data[type])
        window.location.href = this.state.data[type]
    }
    openCode(){

    }
    render() {
        return( <div>
            <Header  city={true} />
                <div className="download_header">
                    <div className="title">下载应用</div>

                    <div className="download">
                        <div className="lutu">
                            <img className="logo" alt="" src={require('./../assets/img/icon.png')} />
                            <div className="name">路图旅拍</div>
                            <div className="">记录美好，分享快乐</div>
                        </div>
                        <img className="type" onClick={()=>{this.download('android')}} alt="" src={require('./../assets/img/android.png')} />
                        <img className="type" onClick={()=>{this.download('ios')}} alt="" src={require('./../assets/img/ios.png')} />
                        <div className="code">
                            <img className="min" onClick={()=>{this.openCode()}} alt="" src={require('./../assets/img/code.png')} />
                            <div className="max">
                            {/* {this.state.data.auto}  */}
                                <QRCode
                                    value={this.state.data.auto}  //value参数为生成二维码的链接
                                    size={130} //二维码的宽高尺寸
                                    fgColor="#000000"  //二维码的颜色
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <img className="download_banner" alt="" src={require('./../assets/img/banner-user-1.png')} />
                <img className="download_banner" alt="" src={require('./../assets/img/banner-user-2.png')} />
                <img className="download_banner" alt="" src={require('./../assets/img/banner-user-3.png')} />
                <img className="download_banner" alt="" src={require('./../assets/img/banner-user-4.png')} />
                <Footer />
            </div>
        )
    }
}