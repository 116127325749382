import React, { Component } from 'react';
import { Player } from 'video-react';
import Header from'../component/header'
import Footer from'../component/footer'
import { Link } from "react-router-dom";
import '../style/vlog.css'
import {
    SiteArticleList
  } from "../api/api";
export default class Vlog extends Component{
    state={
        vlogList:[{
            atc_attach:[{file:''}]}]
    }
    componentWillMount(){
        if (this.props.location.state.ac_id) {
            this.SiteArticleList(this.props.location.state.ac_id)
        }
    }
    async SiteArticleList(ac_id){
        let res = await SiteArticleList({ac_id:ac_id})
        if(res.code == 0){
          this.setState({
            vlogList: res.data.list
          })
        }
    }
    render() {
        return (
        <div >
            <Header city={true} />
            <div className="breadcrumb w">
                <Link to='./' ><span>路图全球旅行摄影</span></Link>
                <img src={require('../assets/img/next_page.png')} alt="" />
                <span>VLOG视频</span>
            </div>
            <div className="vlog">
            {
                    this.state.vlogList.map((item,index)=>{
                        return <div className="list_vlog" key={index}>
                            <div className="item">
                            <video data-index="1" controls className="video" width="100%" height="auto" src={item.atc_attach[0].file} poster={item.cover}></video>
                            <p> {item.title}</p>
                        </div>
                        </div>
                    })
                }
                </div>
            <Footer />
        </div>
        )
    }
}