import React, { Component } from 'react';
import { Player } from 'video-react';
import Header from '../component/header'
import Footer from '../component/footer'
import { Link } from "react-router-dom";
import moment from "moment";
import '../style/introduce.css'
import {
    SitePageList
  } from "../api/api";
export default class Introduce extends Component {
    state={
        data:{
            page:{
                img:'http://new.rototravel.cn/uploads/191107/1-19110G21640959.png',
                content:''
            },
            list:{
                list:[ ]
            }
        }
    }
    componentDidMount(){
        this.SitePageList()
    }
    async SitePageList(){
        let res = await SitePageList()
        if(res.code === 0){
          this.setState({
            data: res.data
          })

        }
    }
    render() {
        return (
            <div>
                <Header city={true} />
                <div className="breadcrumb w">
                    <Link to='./' ><span>路图全球旅行摄影</span></Link>
                    <img src={require('../assets/img/next_page.png')} alt="" />
                    <span>公司介绍</span>
                </div>
                <div className="introduce w">
                    <div className="list">
                        {/* <img src={require('../assets/banner1.png')} alt="" /> */}
                        <div className="title">公司新闻</div>
                        {
                            this.state.data.list.list.map((item,index)=>{
                                return <Link  key={index} to={{pathname:"/details", search:`?id=${item.id}`}}>  <div className="item">
                                            <div className="">{item.title}</div>
                                            <div className="time">{moment(parseInt(item.create_time*1000)).format('YYYY-MM-DD HH:mm:ss')}</div>
                                    </div></Link>
                            }) 
                        }
                    </div>
                    <div className="story ">
                        <div className="title">品牌故事</div>
                        <div className="cont" dangerouslySetInnerHTML={{__html: this.state.data.page.content}} />

                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}