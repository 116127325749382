import React from 'react'
import { BrowserRouter as Router, BrowserRouter, Route, Link } from "react-router-dom";
import router from '../Router';
import Footer from '../component/footer'
import Banner from '../pages/banner.jsx'
import '../style/index.css'

class Content extends React.Component {
    componentDidMount() {
    }
    render() {
        return (
            <div className="Content">
                {/* <Banner/> */}
                <Router>
                    <div>
                        {
                            router.map(item => {
                                return (<Route key={item.path} exact={item.exact} path={item.path} component={item.component} />)
                            })
                        }
                    </div>
                    {/* <Footer /> */}
                </Router>

            </div>
        )
    }
}

export default Content;