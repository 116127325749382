import axios from 'axios'
import QS from 'qs'

let http={
    post: '',
    get: ''
}
const AXIOS = axios.create({
    // baseURL: 'http://web.api.rototravel.cn/',//'http://admin.api.rototravel.cn/'
    // headers:{'X-TOKEN':localStorage.getItem('token')},
    withCredentials: true, 
    timeout: 30000, 
  })
http.post =  (url,data)=>{
    return new Promise((resolve, reject) => {
        AXIOS.post(url.trim(),QS.stringify(data)).then(res => {
          if(res.data.code == 1001002){
          } else if (Number(res.data.code) === 0) {
            resolve(res.data)
          }else{
            resolve(res.data)
          }
        }).catch(err => {
          reject(err)
        })
      })
}
http.get =  (url,data)=>{
    return new Promise((resolve, reject) => {
        AXIOS.get(url.trim(),{params:data}).then(res => {
          if(res.data.code == 1001002){

          } else if (Number(res.data.code) === 0) {
            resolve(res.data)
          }else{
            resolve(res.data)
          }
        }).catch(err => {
          reject(err)
        })
      })
}
export default http