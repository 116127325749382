import React, { Component } from 'react';
import { Player } from 'video-react';
import Header from'../component/header'
import Footer from'../component/footer'
import {Link } from "react-router-dom";
import '../style/country.css'
import {
    SiteArticleList
  } from "../api/api";
export default class Country extends Component{
    state={
        albumList:[],
        id:1 
    }
    componentWillMount(){
        this.SiteArticleList(this.state.id)
    }
    tabChange(tab){
        this.props.history.push({ pathname: `/country${tab}`, search: `?al_ids=${tab}` })
    }
    async SiteArticleList(al_ids){
        let res = await SiteArticleList({al_ids:al_ids})
        if(res.code == 0){
          this.setState({
            albumList: res.data.list
          })
        }
    }
    img(num){
        if (num%2 ==0) {
            return  <img alt="" src={require('../assets/img/more.png')} />
         } else {
            return <img alt="" src={require('../assets/img/more2.png')} />
         }
    }
    render() {
        
        return (
        <div>
            <Header city={true} />
            <div className="breadcrumb w">
                <Link to='./' ><span>路图全球旅行摄影</span></Link>
                <img src={require('../assets/img/next_page.png')} alt="" />
                <span>客片欣赏</span>
                <img src={require('../assets/img/next_page.png')} alt="" />
                <span>{
                    this.state.id==1?'情侣客片':
                    this.state.id==2?'个人客片':
                    this.state.id==3?'亲子客片':
                    this.state.id==4?'闺蜜客片':""
                }</span>
            </div>
            <div className="w">
                <div className="tabs">
                    <span onClick={()=>{this.tabChange('1')}} className={this.state.id==1?'active':''}>情侣客片</span>
                    <span onClick={()=>{this.tabChange('2')}} className={this.state.id==2?'active':''}>个人客片</span>
                    <span onClick={()=>{this.tabChange('3')}} className={this.state.id==3?'active':''}>亲子客片</span>
                    <span onClick={()=>{this.tabChange('4')}} className={this.state.id==4?'active':''}>闺蜜客片</span>
                </div>
            </div>
            {
                this.state.albumList.map((item,index)=>{
                    return <div key={item.id} className="country_list">
                        <Link  to={{pathname:"/details",  search:`?id=${item.id}`}}>
                        <img src={item.cover} alt="" />
                        <div className="title">{item.title}</div>
                        <div className="title look"> 
                            <span>查看专辑</span> 
                             {this.img(index) }
                             </div>
                        </Link> </div>
                })
            }
            <Footer />
        </div>
        )
    }
}