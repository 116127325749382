import React from 'react';
import Content from './component/Content.jsx'
function App() {
  return (
    <div className="App">
      <Content />
    </div>
  );
}

export default App;
