import React, { Component } from 'react';
export default class Footer extends Component{
    render() {
        return (
            <div>
               <div className="footer">
                    <div className="w center">
                        <img className="logo" src={require('../assets/img/LOGO.png')} alt="" />
                        <p className="name">北京路图信息技术有限公司</p>
                        <p><a href="https://shltly.fliggy.com">阿里飞猪商城：https://shltly.fliggy.com</a></p>
                        <p><a href="https://lutulp.tmall.com">天猫商城：https://lutulp.tmall.com</a></p>
                        {/* <p><a href="">官方抖音号：路图全球旅行摄/账号： 595602835</a></p> */}
                        <p><a href="https://weibo.com/lututravel">
                        <img alt="" src={require('./../assets/img/weibo.png')}/>
                        官方微博：https://weibo.com/lututravel</a></p>
                        <p>公司地址：北京市朝阳区酒仙桥路14号兆维工业园C3栋2层</p>
                        <p>公司电话：4000-520-155</p>
                        <p>企业QQ：3400598560</p>
                        <p><a href="http://www.miitbeian.gov.cn " target="_blank">沪ICP备17052697号-2</a></p>
                        <div className="imgs">
                            <div>
                                <img alt="" src={require('./../assets/img/roto_xcx.jpg')}/>
                                <div>官方小程序</div>
                            </div>
                            <div>
                                <img alt="" src={require('./../assets/img/wx.png')}/>
                                <div>微信公众号</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}