import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "../node_modules/video-react/dist/video-react.css";
import './style/public.css'
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
// if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
// 	window.location.href = 'http://m.rotophoto.cn'
// } else {
// 	// window.location.href = 'https://www.sohu.com'
// }
