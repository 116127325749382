import SERVR from './server'
const GET = SERVR.get
const POST = SERVR.post
var  baseURL=""
if (process.env.NODE_ENV == 'development') {
  baseURL= ""
} else {
  baseURL="http://web.api.rotophoto.cn/"
}


//官网首页接口
export const SiteHome = (data) => {
  return GET(baseURL+'website/site/home', data)
}
//专辑列表(新闻列表
export const SiteArticleList = (data) => {
  return GET(baseURL+'website/site/article-list', data)
}
//专辑(新闻)详情
export const SiteArticleView = (data) => {
  return GET(baseURL+'website/site/article-view', data)
}
//公司介绍(单片文章-新闻列表)
export const SitePageList = (data) => {
  return GET(baseURL+'website/site/page-list', data)
}
//单页详情接口
export const SitePageView = (data) => {
  return GET(baseURL+'website/site/page-view', data)
}
//app下载地址
export const DownloadUrl = (data) => {
  return GET(baseURL+'download/url', data)
}