import React, { Component } from 'react';
import { Player } from 'video-react';
import Header from'../component/header'
import Footer from'../component/footer'
import {Link } from "react-router-dom";
import {
    SiteArticleList
  } from "../api/api";
// import Footer from'../component/footer'
import '../style/city.css'
export default class City extends Component{
    state={
        CityList:[],
        city:''
    }
    componentWillMount(){
        const params = new URLSearchParams(this.props.location.search)
        if (params.get("desin_id")) {
            this.setState({
                city:params.get("name")
            })
            this.SiteArticleList(params.get("desin_id"))
        }
    }
    async SiteArticleList(desin_id){
        let res = await SiteArticleList({desin_id:desin_id})
        if(res.code == 0){
          this.setState({
            CityList: res.data.list
          })
        }
    }
    img(num){
        if (num%2 ==0) {
            return  <img alt="" src={require('../assets/img/more.png')} />
         } else {
            return <img alt="" src={require('../assets/img/more2.png')} />
         }
    }
    render() {
        return (
        <div>
            <Header city={true} />
            <div className="">
            <div className="breadcrumb w">
                <Link to='./' ><span>路图全球旅行摄影</span></Link>
                <img src={require('../assets/img/next_page.png')} alt="" />
                <span>旅拍目的地</span>
                <img src={require('../assets/img/next_page.png')} alt="" />
                <span>{this.state.city||'目的地列表'}</span>
            </div>
            {
            this.state.CityList.map((item,index)=>{
                return <div className="city" key={item.id}>
                    <Link  to={{pathname:"/details", search:`?id=${item.id}`}}>
                        <div className="">
                            <img src={item.cover} alt="" />
                        </div>
                        <div>
                            <div className="title">{item.title}</div>
                            <div className="title look"><span>查看专辑</span> 
                            {this.img(index) }
                             </div>
                        </div>
                    </Link>
                </div>
            })
            }
            
        </div>
            <Footer />
        </div>
        )
    }
}