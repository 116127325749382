import React, { Component } from 'react';
import { Redirect, BrowserHistory, Link } from "react-router-dom";
import {
    SiteHome
  } from "../api/api";
export default class Header extends Component{
    state = {
        catetory: [
            {
                id:1,
                title:'客片欣赏'
            },
            {
                id:2,
                title:'VLOG视频'
            },
            {
                id:3,
                title:'旅拍目的地'
            },
            {
                id:4,
                title:'优惠活动'
            },
            {
                id:6,
                title:'路图APP'
            },
            {
                id:5,
                title:'公司介绍'
            },
        ],
    }

    componentDidMount() {
        if (this.props.city) {
            this.setState({
              city:true
            })
          }
        // this.SiteHome()
        window.addEventListener('scroll', this.handleScroll);
    }
    handleScroll() {
        var scroH = document.documentElement.scrollTop; //滚动高度
        var h =0
        if ( document.getElementById('header_video')) {
            h =document.getElementById('header_video').offsetHeight || 0
        } else {
            h =0
        }
        if (scroH > h) {
            if (document.getElementById('header')) {
                document.getElementById('header').setAttribute('class', 'fixed_top header');
                document.getElementById('header_height').setAttribute('style','padding-top: 60px')
            }
        } else {
            if (document.getElementById('header')) {
                document.getElementById('header').removeAttribute('class', 'fixed_top');
                document.getElementById('header').setAttribute('class', 'header');
                document.getElementById('header_height').setAttribute('style','padding-top: 0px')
            }
        }
    }
    openKeFu(){
        window.ysf('open', {
            templateId:123
        });
    }
      CityScroll(){
        let anchorElement = document.getElementById('city')
        if(anchorElement) {
            setTimeout(() => {
            anchorElement.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
            }, 30)
        }
      }
      cityHtml(){
        if (this.state.city) {
          return <Link key="旅拍目的地" to={{pathname:"/", state:{city:true}}} >
          <div className="nav_item"> 旅拍目的地 </div></Link>
        } else {
          return <div key='旅拍目的地' className="nav_item" onClick={()=>{this.CityScroll()}}> 旅拍目的地</div>
        }
      }
    render() {

        return (
            <div>
                <div className="a header" id="header">
                    <div className="w nav">

                        <div className="logo">
                            <Link to='./' >
                            <img src={require('../assets/img/LOGO.png')} alt="" />
                            <h1>路图</h1>
                            </Link>
                        </div>
                        <div className="nav_item">
                            <Link to='./' >
                            首页
                            </Link>
                         </div>
                         {
                             this.state.catetory.map(item=>{
                                 if (item.id == 1) {
                                    return <div key={item.id} className="nav_item">
                                    <span>客片欣赏</span>
                                    <div className="imgs">
                                        <img className="down" src={require('../assets/img/down.png')} alt="" />
                                        <img className="up" src={require('../assets/img/up.png')} alt="" />
                                    </div>
                                    <ul className="lists">
                                        <li><Link key={item.id} to={{pathname:"/country1"}} >
                                            情侣</Link></li>
                                        <li><Link key={item.id} to={{pathname:"/country2"}} >
                                            个人</Link></li>
                                        <li><Link key={item.id} to={{pathname:"/country3"}} >
                                            亲子</Link></li>
                                        <li><Link key={item.id} to={{pathname:"/country4"}} >
                                            闺蜜</Link></li>
                                    </ul>
                                </div>
                                 } else if (item.id == 2){
                                    return <Link key={item.id} to={{pathname:"/vlog", state:{ac_id:item.id}}} >
                                         <div className="nav_item">
                                         VLOG视频
                                    </div></Link>
                                 } else if (item.id == 3){
                                    return this.cityHtml()
                                 } else if (item.id == 4){
                                    return <div key={item.id} className="nav_item" id="kefu" onClick={()=>{this.openKeFu()}}>
                                        优惠活动
                                    </div>
                                 }else if (item.id == 6){
                                    return <Link key={item.id} to={{pathname:"/download",}} >
                                         <div className="nav_item">
                                         路图APP
                                    </div></Link>
                                 } else if (item.id == 5){
                                    return <Link key={item.id} to={{pathname:"/introduce", state:{ac_id:item.id}}} >
                                         <div className="nav_item">
                                         公司介绍
                                    </div></Link>
                                 }

                             })
                         }

                    </div>
                </div>
                <div id="header_height"></div>
            </div>
        )
    }
}